import {
  Avatar,
  Button,
  DonutChart,
  Modal,
  Select,
  Tag,
  Text,
} from "../../Components";
import {
  ApplicationBasicInfo,
  ApplicationBasicSecond,
  ApplicationItemWrapper,
  ApplicationTitle,
  ApplicationTitleWrapper,
  LabelInfoWrapper,
  IndustryWrapper,
  ApplicationsListWrapper,
  ApplicantProfileContent,
  ProfileInfoWrapper,
  BasicInfo,
  NameInfo,
  AppliedTimeWrapper,
  RightSection,
  SecondaryInfoWrapper,
  BasicTitleInfo,
  SecondaryInfoList,
  InsightsWrapper,
  InsightsTitle,
  InsightInfoWrapper,
  InsightsContentWrapper,
  InsightInfoContent,
  InsightInfoList,
  InsightDetailWrapper,
  ChartsWrapper,
  InsightChartWrapper,
  DonutChartWrapper,
  ChartLegendWrapper,
  ChartLegend,
  LegendTitle,
  ActionButtonsWrapper,
  RejectReasonWrapper,
  ApplicationSecondaryInfo,
} from "./BrandApplications.styles";
import * as services from "./BrandApplications.services";
import * as types from "./BrandApplications.types";
import * as utilities from "./BrandApplications.utilities";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { ValueOf } from "../../Utilities/Types";
import * as CONSTANTS from "./BrandApplications.constants";
import { dateTime } from "../../Utilities/DateTime";
import { useUpdateQueryParams } from "../../Hooks/Router.hooks";
import { useEffect, useMemo, useState } from "react";
import WomanOutlined from "../../Assets/Icons/WomanOutlined";
import ManOutlined from "../../Assets/Icons/ManOutlined";

function LabelInfo({ title, value }: types.LabelInfoProps) {
  return (
    <div>
      <Text fontSize="SMALL" fontWeight="SEMI_BOLD">
        {value}
      </Text>
      <Text fontSize="X_SMALL" color="SECONDARY_TEXT">
        {title}
      </Text>
    </div>
  );
}

function ApplicationItem({ application, path }: types.ApplicationItemProps) {
  const { partner, insights } = application;
  return (
    <Link to={path}>
      <ApplicationItemWrapper>
        <ApplicationTitleWrapper>
          <Avatar
            src={partner?.profile_picture}
            alt={partner?.name}
            size={40}
          />
          <ApplicationBasicInfo>
            <ApplicationTitle fontWeight="SEMI_BOLD" fontSize="SMALL">
              {partner?.name}
            </ApplicationTitle>
            <ApplicationBasicSecond>
              <Text fontSize="X_SMALL" color="SECONDARY_TEXT">
                Application ID: {application?.id}
              </Text>
            </ApplicationBasicSecond>
          </ApplicationBasicInfo>
        </ApplicationTitleWrapper>
        <ApplicationSecondaryInfo>
          <Text fontWeight="SEMI_BOLD" color="SECONDARY_TEXT" lineHeight="1">
            {partner.gender === "female" ? <WomanOutlined /> : <ManOutlined />}
          </Text>
          <Text
            fontSize="X_SMALL"
            fontWeight="SEMI_BOLD"
            color="SECONDARY_TEXT"
          >
            {partner?.gender}
          </Text>
          {application?.tag ? (
            <Tag color="GREEN">{application?.tag}</Tag>
          ) : null}
        </ApplicationSecondaryInfo>
        <LabelInfoWrapper>
          {insights.map((insight, index) => (
            <LabelInfo
              key={index}
              title={insight?.label}
              value={insight?.value}
            />
          ))}
        </LabelInfoWrapper>

        {partner?.industries && partner.industries.length > 0 ? (
          <IndustryWrapper>
            {partner.industries.slice(0, 2).map((industry, index) => (
              <Tag key={index} color="GREY">
                {industry}
              </Tag>
            ))}
            {partner?.industries?.length > 2 ? (
              <Tag color="GREY">+2</Tag>
            ) : null}
          </IndustryWrapper>
        ) : null}
      </ApplicationItemWrapper>
    </Link>
  );
}

export function ApplicationsList({
  campaignId,
  status,
}: types.ApplicationsListProps) {
  const { data } = useQuery({
    queryKey: ["campaignApplications", campaignId, status],
    queryFn: () => services.getCampaignApplications(campaignId, status),
    select: utilities.transformApplicationList,
  });
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  function getUpdatedUrl(applicationId: number) {
    const newParams = new URLSearchParams(params);
    newParams.set("application_id", applicationId.toString());
    return `${location.pathname}?${newParams.toString()}`;
  }

  return (
    <ApplicationsListWrapper>
      {data?.applications.map((application, index) => (
        <ApplicationItem
          key={application.id}
          application={application}
          path={getUpdatedUrl(application.id)}
        />
      ))}
    </ApplicationsListWrapper>
  );
}

function SecondaryInfo({ content }: types.SecondaryInfoProps) {
  return (
    <SecondaryInfoWrapper>
      <Text fontSize="X_SMALL" color="SECONDARY_TEXT">
        {content}
      </Text>
    </SecondaryInfoWrapper>
  );
}

function ProfileInfo({
  partner,
  application,
  description,
}: types.ProfileInfoProps) {
  return (
    <ProfileInfoWrapper>
      <Avatar
        src={partner?.profile_picture}
        alt={partner?.name}
        size={150}
        shape="square"
      />
      <RightSection>
        <BasicInfo>
          <BasicTitleInfo>
            <NameInfo>
              <Text fontSize="X_LARGE" fontWeight="SEMI_BOLD">
                {partner?.name}
              </Text>
              <Tag color="GREEN">{application.tag}</Tag>
            </NameInfo>
            <AppliedTimeWrapper>
              <Text fontSize="X_SMALL" color="SECONDARY_TEXT">
                Applied on:
              </Text>
              {application.applied_on ? (
                <Text fontSize="X_SMALL" fontWeight="SEMI_BOLD">
                  {dateTime.formatDayMonthYear(application?.applied_on)}
                </Text>
              ) : null}
            </AppliedTimeWrapper>
          </BasicTitleInfo>
          <SecondaryInfoList>
            {partner?.locations && partner?.locations.length > 0 ? (
              <SecondaryInfo content={partner.locations.join(", ")} />
            ) : null}
            <SecondaryInfo content={partner?.gender} />
            {partner?.languages && partner?.languages.length > 0 ? (
              <SecondaryInfo content={partner.languages.join(", ")} />
            ) : null}
          </SecondaryInfoList>
        </BasicInfo>
        <Text fontSize="X_SMALL" color="SECONDARY_TEXT">
          {description}
        </Text>
        {partner?.industries && partner.industries.length > 0 ? (
          <IndustryWrapper>
            {partner.industries.map((industry, index) => (
              <Tag key={index} color="GREY">
                {industry}
              </Tag>
            ))}
          </IndustryWrapper>
        ) : null}
      </RightSection>
    </ProfileInfoWrapper>
  );
}

function InsightDelta({ delta }: types.InsightDeltaProps) {
  return delta > 0 ? (
    <Tag color="GREEN" shape="circle">
      ↑{delta}%
    </Tag>
  ) : (
    <Tag color="RED" shape="circle">
      ↓{delta}%
    </Tag>
  );
}

function InsightInfo({ delta, title, value }: types.InsightInfoProps) {
  return (
    <InsightInfoWrapper>
      <Text fontSize="X_SMALL" color="SECONDARY_TEXT" fontWeight="SEMI_BOLD">
        {title}
      </Text>
      <InsightInfoContent>
        <Text fontSize="X_LARGE" fontWeight="SEMI_BOLD">
          {value}
        </Text>
        {delta ? <InsightDelta delta={delta} /> : null}
      </InsightInfoContent>
    </InsightInfoWrapper>
  );
}

function InsightChart({ distribution }: types.InsightChartProps) {
  const chartData = distribution.matches.map((match, index) => ({
    name: match.key,
    value: match.percentage,
    color: CONSTANTS.MATCH_COLORS[index] || CONSTANTS.MATCH_COLORS[0],
  }));
  chartData.push({
    name: distribution.key,
    value: distribution.other.percentage,
    color: CONSTANTS.NON_MATCH_COLOR,
  });
  return (
    <InsightChartWrapper>
      <Text fontSize="SMALL" fontWeight="SEMI_BOLD">
        {distribution.key}
      </Text>
      <Text fontSize="X_SMALL" color="SECONDARY_TEXT">
        Match Count : {distribution?.count}
      </Text>
      <DonutChartWrapper>
        <DonutChart
          chartCenterText={distribution?.count?.toString()}
          data={chartData}
        />
        <ChartLegendWrapper>
          {distribution.matches.map((match, index) => (
            <ChartLegend
              key={index}
              $color={
                CONSTANTS.MATCH_COLORS[index] || CONSTANTS.MATCH_COLORS[0]
              }
            >
              <LegendTitle fontSize="X_SMALL" color="SECONDARY_TEXT">
                {match?.key}:
              </LegendTitle>
              <Text fontSize="X_SMALL" fontWeight="SEMI_BOLD">
                {match?.percentage}%
              </Text>
            </ChartLegend>
          ))}
          <ChartLegend
            key={distribution?.matches?.length}
            $color={CONSTANTS.NON_MATCH_COLOR}
          >
            <LegendTitle fontSize="X_SMALL" color="SECONDARY_TEXT">
              {distribution?.other?.key}:
            </LegendTitle>
            <Text fontSize="X_SMALL" fontWeight="SEMI_BOLD">
              {distribution?.other?.percentage}%
            </Text>
          </ChartLegend>
        </ChartLegendWrapper>
      </DonutChartWrapper>
    </InsightChartWrapper>
  );
}

function Insights({ insights }: types.InsightProps) {
  const insightWithDistribution =
    insights.find((insight) => insight.distributions.length > 0)
      ?.distributions || [];

  return (
    <InsightsWrapper>
      <InsightsTitle fontWeight="SEMI_BOLD" color="SECONDARY_TEXT">
        INSIGHTS
      </InsightsTitle>
      <InsightsContentWrapper>
        <InsightInfoList>
          {insights.map((insight) => (
            <InsightInfo
              key={insight?.parameter}
              title={insight?.parameter}
              value={insight?.count?.toString()}
              delta={insight?.delta}
            />
          ))}
        </InsightInfoList>
        <InsightDetailWrapper>
          <Text fontWeight="SEMI_BOLD">Followers Insights</Text>
          <Text color="SECONDARY_TEXT" fontSize="X_SMALL">
            Get a deeper level of underrating on the audience’s Demographics{" "}
          </Text>
          <ChartsWrapper>
            {insightWithDistribution.map((distribution, index) => (
              <InsightChart key={index} distribution={distribution} />
            ))}
          </ChartsWrapper>
        </InsightDetailWrapper>
      </InsightsContentWrapper>
    </InsightsWrapper>
  );
}

export function ActionButtons({
  campaignId,
  showSave,
}: types.ActionButtonsProps) {
  const [searchParams] = useSearchParams();
  const applicationId = Number(searchParams.get("application_id") || 0);
  const queryClient = useQueryClient();
  const updateQueryParams = useUpdateQueryParams();
  const [confirmActionModal, setConfirmActionModal] = useState<ValueOf<
    typeof CONSTANTS.ACTION_MODAL_TYPES
  > | null>(null);

  const [rejectReason, setRejectReason] = useState<types.RejectReason | null>(
    null
  );

  const { data } = useQuery({
    queryKey: [
      "campaignApplications",
      campaignId,
      CONSTANTS.APPLICATION_STATUS.APPLIED,
    ],
    queryFn: () =>
      services.getCampaignApplications(
        campaignId || "",
        CONSTANTS.APPLICATION_STATUS.APPLIED
      ),
  });

  const { mutateAsync: updateApplicationStatus, isPending } = useMutation({
    mutationFn: services.updateApplicationStatus,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["campaignApplications"] });
      updateQueryParams({ application_id: "" });
    },
  });

  async function handleApplicationStatusChange(
    status: ValueOf<typeof CONSTANTS.APPLICATION_STATUS>
  ) {
    await updateApplicationStatus({ applicationId, status });
  }

  function handleRejectReason(value: unknown) {
    if (typeof value !== "string") {
      return;
    }
    const selectedReason = CONSTANTS.REJECT_REASONS.find(
      (reason) => reason.value === value
    );
    if (!selectedReason) {
      return;
    }
    setRejectReason({
      title: selectedReason.value,
      description: selectedReason.label,
    });
  }

  const isRejectModal =
    confirmActionModal === CONSTANTS.ACTION_MODAL_TYPES.REJECT;

  async function handleConfirmAction() {
    if (!isRejectModal) {
      await handleApplicationStatusChange(
        CONSTANTS.APPLICATION_STATUS.ACCEPTED
      );
      return;
    }
    if (!rejectReason) {
      return;
    }
    await updateApplicationStatus({
      applicationId,
      status: CONSTANTS.APPLICATION_STATUS.REJECTED,
      rejectReason,
    });
    setConfirmActionModal(null);
  }

  const applications = useMemo(() => data?.result.applications || [], [data]);

  useEffect(() => {
    if (applicationId) {
      return;
    }
    if (!applications.length) {
      return;
    }
    updateQueryParams({
      application_id: applications[0].id.toString(),
    });
  }, [applicationId, applications, updateQueryParams]);

  return (
    <ActionButtonsWrapper>
      {showSave ? (
        <Button
          text="Save"
          size="large"
          onClick={() =>
            handleApplicationStatusChange(CONSTANTS.APPLICATION_STATUS.SAVED)
          }
          loading={isPending}
        />
      ) : null}
      <Button
        text="Reject"
        size="large"
        onClick={() =>
          setConfirmActionModal(CONSTANTS.ACTION_MODAL_TYPES.REJECT)
        }
        danger
        loading={isPending}
      />
      <Button
        text="Approve"
        size="large"
        type="primary"
        onClick={() =>
          setConfirmActionModal(CONSTANTS.ACTION_MODAL_TYPES.APPROVE)
        }
        loading={isPending}
      />
      <Modal
        open={!!confirmActionModal}
        title={isRejectModal ? "Reject Application?" : "Approve Application?"}
        onCancel={() => {
          setConfirmActionModal(null);
        }}
        onOkay={handleConfirmAction}
        destroyOnClose
        okayText={isRejectModal ? "Reject" : "Approve"}
        okayButtonProps={{ danger: isRejectModal }}
      >
        {isRejectModal ? (
          <>
            <Text color="SECONDARY_TEXT" fontSize="X_SMALL">
              Are you sure you want to reject this application? Once rejected
              the action can not be undone, you can save applications to
              shortlist and decide later
            </Text>
            <RejectReasonWrapper>
              <Text fontWeight="SEMI_BOLD" fontSize="SMALL">
                Reason for rejection
              </Text>
              <Select
                options={CONSTANTS.REJECT_REASONS}
                onChange={handleRejectReason}
                placeholder="Select a reason"
                optionRenderer={({ label, value }) => (
                  <div>
                    <Text fontSize="SMALL" fontWeight="SEMI_BOLD">
                      {value}
                    </Text>
                    <Text fontSize="X_SMALL" color="SECONDARY_TEXT">
                      {label}
                    </Text>
                  </div>
                )}
              />
            </RejectReasonWrapper>
          </>
        ) : (
          <Text color="SECONDARY_TEXT" fontSize="X_SMALL">
            Are you sure you want to select this application? Once selected the
            action can not be undone, you can save applications to shortlist and
            decide later
          </Text>
        )}
      </Modal>
    </ActionButtonsWrapper>
  );
}

function ApplicantProfile({ applicationId }: types.ApplicantProfileProps) {
  const { data } = useQuery({
    queryKey: ["applicantProfile", applicationId],
    queryFn: () => services.getApplicationData(applicationId),
  });
  if (!data) {
    return null;
  }

  return (
    <ApplicantProfileContent>
      <ProfileInfo
        partner={data.result.partner}
        application={data.result.application}
        description={data.result.socials[0].value.info?.biography}
      />
      <Insights insights={data.result.socials[0].value?.insights} />
    </ApplicantProfileContent>
  );
}

export function ApplicantProfileContext() {
  const [searchParams] = useSearchParams();
  const applicationId = Number(searchParams.get("application_id") || 0);
  if (!applicationId) {
    return null;
  }
  return <ApplicantProfile applicationId={applicationId} />;
}
