import { ButtonLikeLink, Dropdown, Table, Text } from "../../Components";
import {
  ActionWrapper,
  BrandCampaignsWrapper,
  DropdownContent,
  TitleWrapper,
} from "./BrandCampaigns.styles";
import * as CONSTANTS from "./BrandCampaigns.constants";
import * as services from "./BrandCampaigns.services";
import { useQuery } from "@tanstack/react-query";
import * as utilities from "./BrandCampaigns.utilities";
import * as types from "./BrandCampaigns.types";
import { PrimaryAction } from "./BrandCampaigns.HelperComponents";
import { useUpdateCampaignStatus } from "./BrandCampaigns.hooks";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../Constants/styles.constants";
import { ICONS } from "../../Assets/Icons/Icons";
import Fallback from "../../Components/Fallback";
import noneImg from "../../Assets/Images/none.png";
import { useState } from "react";

function BrandCampaigns() {
  const { data, isPending } = useQuery({
    queryKey: ["brandCampaigns"],
    queryFn: () => services.getCampaigns(),
    select: utilities.transformMyCampaigns,
  });

  const navigate = useNavigate();
  const { updateCampaignCreateStatus } = useUpdateCampaignStatus();

  function handleSecondaryActionClick(
    record: types.TransformedCampaign,
    key?: number | bigint | string | undefined
  ) {
    const action = record.actions?.secondary?.find(
      (action) => action.cta === key
    );
    if (!action) {
      return;
    }
    if (action.onclick.path) {
      navigate(action.onclick.path);
      return;
    }
    if (action.onclick.url) {
      window.open(
        action.onclick.url,
        action.onclick.new_tab ? "_blank" : "_self"
      );
      return;
    }
    if (action.onclick.status_update) {
      updateCampaignCreateStatus({
        campaignId: record.id,
        status: action.onclick.status_update,
      });
    }
  }

  const tableColumns = CONSTANTS.COLUMNS.map((column) => {
    if (column.key !== "actions") {
      return column;
    }
    return {
      ...column,
      render: (text: unknown, record: types.TransformedCampaign) => {
        if (!record.actions) {
          return null;
        }
        return (
          <ActionWrapper>
            <PrimaryAction
              action={record.actions.primary}
              campaignId={record.id}
            />
            {record.actions.secondary ? (
              <Dropdown
                items={record.actions.secondary.map((action) => ({
                  label: action.label,
                  key: action.cta,
                }))}
                onClick={(info) => {
                  handleSecondaryActionClick(record, info?.key);
                }}
                getItemStyle={(label) => {
                  return label === "Reject" ? { color: COLORS.RED500 } : {};
                }}
              >
                <DropdownContent>
                  <div style={{ height: "20px", width: "20px" }}>
                    <img
                      src={ICONS.ThreeDot}
                      alt="3 dot icon"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                </DropdownContent>
              </Dropdown>
            ) : null}
          </ActionWrapper>
        );
      },
    };
  });
  return (
    <BrandCampaignsWrapper>
      <TitleWrapper>
        <Text as="h1" fontWeight="SEMI_BOLD" fontSize="LARGE">
          My Campaigns
        </Text>
      </TitleWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "1rem",
        }}
      >
        {!data?.campaigns || data?.campaigns?.length === 0 ? null : (
          <ButtonLikeLink
            to="/brand/create-campaign"
            text="Create Campaign"
          ></ButtonLikeLink>
        )}
      </div>

      {!data?.campaigns || data?.campaigns?.length === 0 ? (
        <Fallback
          occupyAvailable
          imgSrc={noneImg}
          ctaOnClick={async () => {
            navigate("/brand/create-campaign");
          }}
          ctaTitle="Create campaign"
          loading={false}
          heading="You’ve not created any campaigns yet"
          subText=""
        />
      ) : (
        <>
          <Table
            columns={tableColumns}
            rowKey="id"
            data={data?.campaigns || []}
            loading={isPending}
            customColumns={[
              {
                key: "title",
                component: ({ value }) => <span>{value}</span>,
                width: "15%",
              },
              {
                key: "status",
                component: ({ value }) => <StatusTag value={value} />,
              },
              {
                key: "description",
                component: ({ value }) => (
                  <Description value={value} maxLength={160} />
                ),
                width: "30%",
              },
              {
                key: "goals",
                component: ({ value }) => <span>{value}</span>,
                width: "15%",
              },
            ]}
          />
        </>
      )}
    </BrandCampaignsWrapper>
  );
}

interface DescriptionProps {
  value: string;
  maxLength?: number;
}

const Description: React.FC<DescriptionProps> = ({
  value,
  maxLength = 100,
}) => {
  const [expanded, setExpanded] = useState(false);

  const displayText = expanded ? value : value.slice(0, maxLength);

  return (
    <div style={{ cursor: "pointer" }} onClick={() => setExpanded(!expanded)}>
      <p>
        {displayText}
        {value.length > maxLength && !expanded && "... "}
        <span
          style={{
            color: COLORS.PRIMARY,
          }}
        >
          {expanded ? "Show Less" : value.length > maxLength ? "Show More" : ""}
        </span>
      </p>
    </div>
  );
};

function statusMap(status: types.CampaignStatus): {
  backgroundColor: string;
  color: string;
} {
  const statusColorMap: Record<
    types.CampaignStatus,
    { backgroundColor: string; color: string }
  > = {
    Draft: {
      backgroundColor: COLORS.GREY50,
      color: COLORS.GREY500,
    },
    "Proposal Sent": {
      backgroundColor: COLORS.YELLOW50,
      color: COLORS.YELLOW500,
    },
    "Brand Requested Changes": {
      backgroundColor: COLORS.ORANGE50,
      color: COLORS.ORANGE500,
    },
    "Brand Rejected": {
      backgroundColor: COLORS.RED50,
      color: COLORS.RED500,
    },
    "Brand Approved": {
      backgroundColor: COLORS.GREY50,
      color: COLORS.GREY500,
    },
    Launched: {
      backgroundColor: COLORS.GREEN50,
      color: COLORS.GREEN500,
    },
    Ended: {
      backgroundColor: COLORS.GREY50,
      color: COLORS.GREY500,
    },
  };

  return statusColorMap[status];
}

function StatusTag({ value }: { value: types.CampaignStatus }) {
  return (
    <div
      style={{
        ...statusMap(value),
        padding: "2px 8px",
        fontWeight: "600",
        fontSize: "12px",
        borderRadius: "4px",
        minHeight: "24px",
        width: "auto",
        display: "inline-block",
      }}
    >
      <span>{value}</span>
    </div>
  );
}

export default BrandCampaigns;
