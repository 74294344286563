import styled from "styled-components";
import { COLORS } from "../../Constants/styles.constants";

export const Header = styled.div`
  padding: 16px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${COLORS.WHITE};
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 70px;
`;

export const PageHeading = styled.h1`
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
`;

export const MainSection = styled.main`
  background-color: ${COLORS.GREY50};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  height: calc(100vh - 140px);
  padding-top: 70px;
  margin-top: 70px;
  margin-bottom: 70px;
`;

export const MainSectionContent = styled.div`
  margin: 60px 0px;
`;

export const MainSectionHeading = styled(PageHeading)`
  text-align: center;
  margin-bottom: 24px;
`;

// export const TutCardBG = styled.div`
//   width: 392px;
//   height: 487px;
//   border-radius: 196px;
//   background-color: ${COLORS.PRIMARY_LIGHT_BACKGROUND_COLOR};
//   position: absolute;
//   left: 50%;
//   top: -60%;
//   transform: translateX(-50%);
//   z-index: 1;
// `;

export const TutCards = styled.div`
  display: flex;
  gap: 28px;
`;

export const TutCardCont = styled.div`
  background-color: ${COLORS.WHITE};
  width: 346px;
  border: 2px solid #eceef1;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 8px;
`;

export const TutCardBGContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 24px;
  padding-bottom: 60px;
  border-bottom-left-radius: 160px;
  border-bottom-right-radius: 160px;
  background-color: ${COLORS.PRIMARY_LIGHT_BACKGROUND_COLOR};
  margin-bottom: 8px;
`;

export const TutNumber = styled.div`
  height: 50px;
  width: 50px;
  background-color: ${COLORS.PRIMARY};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-weight: 700;
    font-size: 35px;
    line-height: 40px;
    text-align: center;
    color: ${COLORS.WHITE};
  }
`;

export const TutTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: ${COLORS.GREY500};
  text-align: center;
`;

export const TutLinkCard = styled.div`
  margin-left: 28px;
  margin-right: 28px;
  margin-bottom: 24px;
  padding: 12px;
  background-color: ${COLORS.PRIMARY_LIGHT_BACKGROUND_COLOR};
`;

export const ThumbnailCont = styled.div`
  background-color: #8a9ef2;
  width: 100%;
  height: 140px;
  margin-bottom: 12px;
`;

export const TutName = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 4px;
`;

export const TutDescription = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: ${COLORS.GREY500};
`;

export const Footer = styled.div`
  padding: 16px 18px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: ${COLORS.WHITE};
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 70px;
`;

export const SuccessCont = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background-color: ${COLORS.WHITE};
`;
