import { useMutation } from "@tanstack/react-query";
import { Form as AntdForm } from "antd";

import {
  Button,
  ButtonLikeLink,
  Form,
  FormItem,
  Input,
  Text,
} from "../../Components";
import {
  FormWrapper,
  ResetRight,
  ResetRightContent,
} from "./ForgotPassword.styles";
import successIllustration from "../../Assets/Images/success.png";
import errorIllustration from "../../Assets/Images/error.png";

import * as types from "./ForgotPassword.types";
import * as services from "./ForgotPassword.services";
import { useParams, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { AUTH } from "../../Constants";
import { validatePassword } from "../Login/Login.utilities";
import { PasswordInstructor } from "../Login/Login.HelperComponents";
import {
  VerifyEmailActions,
  VerifyEmailContainer,
  VerifyEmailContent,
  VerifyEmailImage,
} from "../VerifyEmail/VerifyEmail.styles";
import { AnchorLikeLink } from "../../Components/ButtonLikeLink/ButtonLikeLink.component";

export default function ForgotPassword() {
  const { token } = useParams();
  const navigate = useNavigate();
  const resetPasswordMutation = useMutation({
    mutationFn: services.postResetPassword,
  });

  const [form] = AntdForm.useForm();
  const [isFormValid, setIsFormValid] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);
  const [password, setPassword] = useState("");
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [countdown, setCountdown] = useState(3);

  const validateForm = useCallback(async () => {
    if (!hasInteracted) return;
    try {
      await form.validateFields({ validateOnly: true });
      setIsFormValid(true);
    } catch (error) {
      setIsFormValid(false);
    }
  }, [form, hasInteracted]);

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!hasInteracted) {
      setHasInteracted(true);
    }
    validateForm();
  };

  const handlePasswordChange = (value: string) => {
    if (!hasInteracted) {
      setHasInteracted(true);
    }
    setPassword(value);
    form.setFieldsValue({ password: value });
    validateForm();
  };

  async function handleResetPassword(formData: types.ForgotPasswordFormData) {
    resetPasswordMutation.mutate(formData);
  }

  useEffect(() => {
    if (!token) {
      return;
    }
    localStorage.setItem(AUTH.AUTH_TOKEN_LOCAL_STORAGE_KEY, token);
  }, [token]);

  useEffect(() => {
    if (resetPasswordMutation.isSuccess) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      if (countdown === 0) {
        navigate("/login");
      }

      return () => clearInterval(timer);
    }
  }, [resetPasswordMutation.isSuccess, countdown, navigate]);

  return (
    <ResetRight>
      <ResetRightContent>
        {resetPasswordMutation.isSuccess ? (
          <VerifyEmailContainer>
            <VerifyEmailContent>
              <VerifyEmailImage
                style={{ width: "100px" }}
                src={successIllustration}
                alt="success"
              />
              <Text
                style={{
                  fontSize: "24px",
                  lineHeight: "30px",
                }}
                lineHeight="1"
                fontSize="LARGE"
                fontWeight="SEMI_BOLD"
                textAlign="CENTER"
              >
                Password reset successful
              </Text>
              <Text
                style={{
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
                lineHeight="1"
                color="SECONDARY_TEXT"
                textAlign="CENTER"
                fontSize="X_SMALL"
              >
                Returning to login page in {countdown}
              </Text>
              <VerifyEmailActions>
                <ButtonLikeLink to="/login" text="Go to Login" />
              </VerifyEmailActions>
            </VerifyEmailContent>
          </VerifyEmailContainer>
        ) : resetPasswordMutation.isError ? (
          <VerifyEmailContainer>
            <VerifyEmailContent>
              <VerifyEmailImage
                style={{ width: "100px" }}
                src={errorIllustration}
                alt="success"
              />
              <Text
                style={{
                  fontSize: "24px",
                  lineHeight: "30px",
                }}
                lineHeight="1"
                fontSize="LARGE"
                fontWeight="SEMI_BOLD"
                textAlign="CENTER"
              >
                Password reset failed!
              </Text>
              <Text
                style={{
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
                lineHeight="1"
                color="SECONDARY_TEXT"
                textAlign="CENTER"
                fontSize="X_SMALL"
              >
                Sorry there seems to be a problem in reseting your account
                password
              </Text>
              <VerifyEmailActions>
                <AnchorLikeLink
                  href="https://ryme.ai/support"
                  text="Contact Support"
                />
              </VerifyEmailActions>
              <VerifyEmailActions style={{ paddingTop: "10px" }}>
                <ButtonLikeLink
                  to="/login"
                  text="Back to Login"
                  style={{
                    backgroundColor: "transparent",
                    border: `1px solid #AEB6C4`,
                    color: "#000",
                  }}
                />
              </VerifyEmailActions>
            </VerifyEmailContent>
          </VerifyEmailContainer>
        ) : (
          <>
            <Text
              style={{
                fontSize: "24px",
                lineHeight: "30px",
                marginBottom: "6px",
              }}
              fontWeight="SEMI_BOLD"
              fontSize="XXX_LARGE"
              as="h1"
              lineHeight="1"
              textAlign="CENTER"
            >
              Reset your password
            </Text>
            <Text
              color="SECONDARY_TEXT"
              style={{
                lineHeight: "20px",
                fontSize: "14px",
                marginBottom: "30px",
              }}
              textAlign="CENTER"
            >
              Please set up a new password
            </Text>

            <FormWrapper>
              <Form<types.ForgotPasswordFormData>
                onSubmit={handleResetPassword}
                form={form}
              >
                <div style={{ position: "relative" }}>
                  <PasswordInstructor
                    password={password}
                    isVisible={isPasswordFocused}
                  />
                  <FormItem
                    style={{ position: "relative" }}
                    label={<Text>Enter a new password</Text>}
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                      ({}) => ({
                        validator(_, value) {
                          if (!value) {
                            return Promise.resolve();
                          }
                          const passwordError = validatePassword(value);
                          if (!passwordError) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Sorry, your password is not strong")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input
                      placeholder="Enter your password"
                      type="password"
                      onChange={(e) => handlePasswordChange(e.target.value)}
                      onFocus={() => setIsPasswordFocused(true)}
                      onBlur={() => setIsPasswordFocused(false)}
                    />
                  </FormItem>
                </div>

                <FormItem
                  label={<Text>Re-enter your password</Text>}
                  name="confirmPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The new password that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    placeholder="Re-enter your password"
                    type="password"
                    onChange={handleFieldChange}
                  />
                </FormItem>
                <FormItem>
                  <Button
                    text="Reset Password"
                    fullWidth
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={resetPasswordMutation.isPending}
                    disabled={!isFormValid}
                  />
                </FormItem>
              </Form>
            </FormWrapper>
          </>
        )}
      </ResetRightContent>
    </ResetRight>
  );
}
